import AsyncSelect from 'Shared/components/AsyncSelect'
import Select from 'react-select'
import SubscriptionTopicsSelect from 'Shared/components/SubscriptionTopicsSelect'

function FilterParameter({ id, name }) {
  const act = useAct()
  const { parameters, filter } = useSel((s) => s.rules.entities[id])
  const { fields } = useSel((s) => s.availableFilters[filter])
  const value = parameters[name]
  const {
    type,
    entity,
    multiple,
    dependsOn,
    loadStaticList,
    queryParameters = {},
    options = [],
  } = fields[name]
  const selectValue = type === 'select' ? _.find(options, { value }) : null

  const dependencies = Object.fromEntries(
    (dependsOn || []).map((dependency) => {
      if (typeof dependency === 'object') {
        return [dependency.name, parameters[_.camelCase(dependency.slug)] || null]
      } else {
        return [dependency, parameters[_.camelCase(dependency)] || null]
      }
    })
  )

  const finalQueryParameters = {
    ...queryParameters,
    ...dependencies,
  }

  const updateValue = (value) => act.search.upsertFilterParameters(id, { [name]: value })

  const missingDependency = _.includes(dependencies, null)


  return (
    <div className="parameter">
      {type === 'string' && (
        <input type="text" value={value} onChange={(e) => updateValue(e.target.value)} />
      )}
      {type === 'number' && (
        <input type="number" value={value} onChange={(e) => updateValue(e.target.value)} />
      )}
      {type === 'entity' && (
        <AsyncSelect
          entity={entity}
          value={value}
          disabled={missingDependency}
          queryParameters={finalQueryParameters}
          loadStaticList={(dependsOn || []).length > 0 && loadStaticList}
          onChange={updateValue}
          multiple={multiple}
        />
      )}
      {type === 'select' && (
        <div style={{ width: '150px', marginRight: '10px' }}>
          <Select
            width={100}
            options={options}
            value={selectValue}
            onChange={(option) => updateValue(option.value)}
          />
        </div>
      )}
      {type === 'date' && (
        <input type="date" value={value} onChange={(e) => updateValue(e.target.value)} />
      )}
      {type === 'subscription_topic' && (
        <SubscriptionTopicsSelect value={value} onChange={(option) => updateValue(option.value)} />
      )}
    </div>
  )
}

export default FilterParameter
