import {
  createEntityAdapter,
  createSlice,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import { isoDateString } from './functions/util'
import undoable from 'redux-undo'
import { Provider } from 'react-redux'
import { moveDigitOrder, fixDigitOrder, upsertSettings } from 'Shared/sliceHelpers'

// Store if we have any unsaved changes to blocks
const setDirty = (store) => (next) => (action) => {
  if (['blocks/moveDigitOrder', 'blocks/remove', 'blocks/upsert'].includes(action.type)) {
    next({ type: 'emailBuilder/setDirty', payload: true })
  }
  next(action)
}

const middleware = [...getDefaultMiddleware(), setDirty]

const adapter = createEntityAdapter()

const entitySlices = {
  rules: {
    addToGroup(s, { payload: { parentRuleId, id } }) {
      s.entities[parentRuleId].ruleIds.push(id)
    },
    removeNested(s, { payload: { id } }) {
      const rule = s.entities[id]
      if (rule.type == 'group') {
        rule.ruleIds.forEach((nestedId) => {
          delete s.entities[nestedId]
        })
      }
    },
    removeFromGroups(s, { payload: { parentRuleId, id } }) {
      s.entities = _.mapValues(s.entities, (rule) => {
        if (rule.ruleIds) {
          rule.ruleIds = rule.ruleIds.filter((rId) => rId !== id)
        }
        return rule
      })
    },
    upsertFilterParameters(s, { payload: { id, parameters } }) {
      s.entities[id].parameters = { ...s.entities[id].parameters, ...parameters }
    },
  },
  estimates: {},
  questions: { moveDigitOrder, fixDigitOrder, upsertSettings },
  answers: { moveDigitOrder, fixDigitOrder },
}

const undoableEntitySlices = {
  blocks: { moveDigitOrder, fixDigitOrder },
}

function generateSlices(entitySlices, withUndo = false) {
  return _.fromPairs(
    _.map(entitySlices, (extraReducers, name) => {
      const reducer = createSlice({
        name,
        initialState: adapter.getInitialState(),
        reducers: {
          upsert: adapter.upsertOne,
          add: adapter.addOne,
          setAll: adapter.setAll,
          remove: adapter.removeOne,
          ...extraReducers,
        },
      }).reducer
      return [name, withUndo ? undoable(reducer) : reducer]
    })
  )
}

const availableFiltersSlice = createSlice({
  name: 'availableFilters',
  initialState: {},
  reducers: {
    set: (s, a) => a.payload,
  },
})

const createCampaignSliceInitialState = {
  step: 'type',
  type: '',
  subtype: '',
  name: '',
  description: '',
  searchId: null,
  duplicateSearch: false,
}

const createCampaignSlice = createSlice({
  name: 'createCampaign',
  initialState: createCampaignSliceInitialState,
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
    reset: (s, a) => ({ ...createCampaignSliceInitialState, searchId: s.searchId }),
  },
})

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState: {
    id: null,
    campaignType: null,
    name: null,
    search: {
      name: null,
    },
    textMessageBlast: {
      id: null,
      senderType: null,
      senderName: '',
      body: '',
    },
    emailBlast: {
      id: null,
      emailSenderId: null,
      emailTemplateId: null,
      subjectLine: {},
    },
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

export const campaignBuilderSlice = createSlice({
  name: 'campaignBuilder',
  initialState: {
    currentEditor: null,
    loading: true,
    groups: [],
    mergeTags: [],
    whatsAppTemplates: [],
    renderedContent: { body: '' },
    renderedBody: ''
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const localSlice = createSlice({
  name: 'local',
  initialState: {
    demo: false,
  },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const reportingSlice = createSlice({
  name: 'reporting',
  initialState: {
    currentQuery: null,
    points: [],
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
    notification: null,
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const scriptBuilderSlice = createSlice({
  name: 'scriptBuilder',
  initialState: {
    adding: false,
    questions: {},
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const searchSlice = createSlice({
  name: 'search',
  initialState: {},
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const searchBuilderSlice = createSlice({
  name: 'searchBuilder',
  initialState: {
    activeGroupId: null,
    description: null,
    showFilterList: false,
    showTargetTable: false,
    step: 'rules',
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const sendBlastSlice = createSlice({
  name: 'sendBlast',
  initialState: {
    open: false,
    sending: false,
    sent: 0,
    total: 0,
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const sendEmailBlastSlice = createSlice({
  name: 'sendEmailBlast',
  initialState: {
    open: false,
    sending: false,
    sent: 0,
    total: 0,
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const shareCampaignSlice = createSlice({
  name: 'shareCampaign',
  initialState: {
    open: false,
    error: null,
    success: null,
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const targetSlice = createSlice({
  name: 'target',
  initialState: {},
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const targetProfileSlice = createSlice({
  name: 'targetProfile',
  initialState: {
    tab: 'info',
  },
  reducers: {
    set: (s, a) => a.payload,
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: {},
  reducers: {
    set: (s, a) => a.payload,
  },
})

const emailBuilderSlice = createSlice({
  name: 'emailBuilder',
  initialState: {
    activeBlockId: null,
    blockTemplates: null,
  },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
    setActiveBlockId: (s, a) => ({ ...s, activeBlockId: a.payload }),
    setBlockTemplates: (s, a) => ({ ...s, blockTemplates: a.payload }),
    setDirty: (s, a) => ({ ...s, dirty: a.payload }),
  },
})

const emailTemplateSlice = createSlice({
  name: 'emailTemplate',
  initialState: {},
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const defaultDateRange = () => {
  const dateFrom = new Date()
  dateFrom.setDate(dateFrom.getDate() - 7)
  const dateTo = new Date()
  return { from: isoDateString(dateFrom), to: isoDateString(dateTo) }
}

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    loading: true,
    data: {},
    dateRange: defaultDateRange(),
  },
  reducers: {
    upsert: (s, a) => ({ ...s, ...a.payload }),
  },
})

const store = configureStore({
  middleware,
  reducer: {
    availableFilters: availableFiltersSlice.reducer,
    createCampaign: createCampaignSlice.reducer,
    campaign: campaignSlice.reducer,
    campaignBuilder: campaignBuilderSlice.reducer,
    local: localSlice.reducer,
    notifications: notificationsSlice.reducer,
    reporting: reportingSlice.reducer,
    scriptBuilder: scriptBuilderSlice.reducer,
    searchBuilder: searchBuilderSlice.reducer,
    search: searchSlice.reducer,
    sendBlast: sendBlastSlice.reducer,
    sendEmailBlast: sendEmailBlastSlice.reducer,
    shareCampaign: shareCampaignSlice.reducer,
    targetProfile: targetProfileSlice.reducer,
    target: targetSlice.reducer,
    currentUser: currentUserSlice.reducer,
    emailBuilder: emailBuilderSlice.reducer,
    emailTemplate: emailTemplateSlice.reducer,
    analytics: analyticsSlice.reducer,
    ...generateSlices(entitySlices),
    ...generateSlices(undoableEntitySlices, true),
  },
})

export const withStore = (component: React.FunctionComponent<any>) => {
  return (props) => {
    return <Provider store={store}>{React.createElement(component, props)}</Provider>
  }
}
export type StoreType = typeof store
export type DispatchType = typeof store.dispatch
export type StoreStateType = ReturnType<typeof store.getState>

export default store
