import CcTitle from '../../Shared/components/CommaChameleon/CcTitle'
import CommaChameleon from '../../Shared/components/CommaChameleon/index'
import './Wizard.scoped.scss'

const ACTIONS = [
  {
    id: 'upload-people',
    icon: SvgIconsPersonBlue,
    title: 'Upload',
    description:
      'Upload new members and supporters, and update data on existing people with an option to create a list.',
  },
  {
    id: 'upload-list',
    icon: SvgIconsUsers,
    title: 'Create list',
    description:
      'Upload membership numbers, phone numbers, or emails to create a list from existing members and supporters.',
  },
]

const DEFAULT_HEADING = 'Upload member and supporter data'

const CORE_FIELDS = [
  { name: 'Phone Number', type: 'phone', required: false },
  { name: 'Email', required: false, regex: /.*/ },
  { name: 'Join Date', required: false, regex: /^\d{4}-\d{2}-\d{2}$/ },
  { name: 'Membership Status', required: false, regex: /.*/ },
  { name: 'Date of Birth', required: false, regex: /^\d{4}-\d{2}-\d{2}$/ },
  { name: 'Address 1', required: false, regex: /.*/ },
  { name: 'Address 2', required: false, regex: /.*/ },
  { name: 'Address 3', required: false, regex: /.*/ },
  { name: 'Address 4', required: false, regex: /.*/ },
  { name: 'City', required: false, regex: /.*/ },
  { name: 'Postcode', required: false, regex: /.*/ },
  { name: 'Subscription Email', required: false, regex: /^$|y|n/ },
  { name: 'Subscription SMS', required: false, regex: /^$|y|n/ },
  { name: 'Subscription Phone', required: false, regex: /^$|y|n/ },
  { name: 'Subscription Whatsapp', required: false, regex: /^$|y|n/ },
  { name: 'Create User', required: false, regex: /^$|y|n/ },
]

const Wizard = ({ isOpen, onDismiss, minimumData, action }) => {

  const currentAction = (action) => {
    return ACTIONS.find(({ id }) => id === action)
  }

  const heading = (action) => {
    const result = currentAction(action)
    return result ? result.title : DEFAULT_HEADING
  }

  return (
    <div className="no-padding cc-card">
      <CcTitle
        title={heading(action)}
        onClose={onDismiss}
        className={action === 'init' ? '--level-one' : '--level-three'}
      />
      {action === 'upload-people' && (
        <CommaChameleon
          type="upload-people"
          schema={[
            { name: 'Membership Number', slug: 'membership_number', core: true, required: true, requiredForCreation: true, regex: /.*/ },
            { name: 'First Name', type: 'name', slug: 'first_name', core: true, required: minimumData.includes('first_name'), requiredForCreation: true },
            { name: 'Last Name', type: 'name', slug: 'last_name', core: true, required: minimumData.includes('last_name'), requiredForCreation: true },
            ...CORE_FIELDS,
          ]}
          upload={{
            method: 'rails',
            endpoint: {
              path: '/api/uploads',
              model: 'Upload',
              attribute: 'csv',
              method: 'POST',
            },
            forwardUrl: '/admin/audience/all',
            forwardAuto: false,
          }}
        />
      )}
      {action === 'upload-list' && (
        <CommaChameleon
          labels={{}}
          type="upload-list"
          schema={[
            { name: 'Membership Number', required: true, regex: /.*/ },
            { name: 'Phone Number', type: 'phone', required: true },
            { name: 'Email', required: true, regex: /.*/ },
          ]}
          upload={{
            method: 'rails',
            endpoint: {
              path: '/api/uploads',
              model: 'Upload',
              attribute: 'csv',
              method: 'POST',
            },
            forwardUrl: '/admin/lists',
            forwardAuto: false,
          }}
          matchMode={true}
        />
      )}
    </div>
  )
}

export default Wizard
