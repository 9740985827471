import AsyncSelect from 'Shared/components/AsyncSelect'
import Step from './Step'
import MenuListWithFooter from 'Shared/components/MenuListWithFooter'
import { components } from 'react-select'
import Tag from 'Shared/components/Tag'
import PillEditor from 'Shared/components/PillEditor/PillEditor'
import SvgMail from 'images/icons/Mail'

import './EmailSubject.scoped.scss'

const SUBJECT_MAX_LENGTH = 150

function EmailSubjectShortDescriptor({ complete, subject, preview }) {
  return (
    <div>
      {complete && <div>
        <p>{ subject }</p>
        <p className='no-margin-bottom no-margin-top italic incomplete'>{ preview }</p>
      </div> }
      {!complete && <p className='incomplete no-margin-bottom'>What&rsquo;s the first thing the recipient sees?</p>}
    </div>
  )
}

const EmailSubject = () => {
  const act = useAct()

  const { mergeTags, id, currentEditor, renderedBody } = useSel(s => s.campaignBuilder)
  const { 
    emailBlast: {
      subjectLine: {subjectLine, previewLine} = {} 
    } = {} 
  } = useSel(s => s.campaign)

  const complete = !!subjectLine 

  const updateSubject = (subjectLine) => act.campaign.upsertSubjectLine({ subjectLine })
  const updatePreview = (previewLine) => act.campaign.upsertSubjectLine({ previewLine })

  return <Step
    name='Subject'
    editor='subject'
    complete={complete}
    shortDescriptor={ <EmailSubjectShortDescriptor complete={complete} subject={subjectLine} preview={previewLine} /> }
  >
    <div className='row'>
      <div className='column two-thirds'>

        <div className="field">
          <div className='character-counter'>
            <label>Subject line</label>
            <p className='count'><span className='ellipsis narrower'></span> {(subjectLine || '').length} / {SUBJECT_MAX_LENGTH} characters</p>
          </div>
          <PillEditor initialValue={subjectLine} onChange={updateSubject} availableTags={mergeTags} condensed singleLine />
        </div>

        <div className="field">
          <div className='character-counter'>
            <label>Preview text (optional)</label>
            <p className='count'><span className='ellipsis narrower'></span> {(previewLine || '').length} / {SUBJECT_MAX_LENGTH} characters</p>
          </div>
          <PillEditor initialValue={previewLine} onChange={updatePreview} availableTags={mergeTags} condensed singleLine />
          <p>Preview text is the first text that appears after the subject in the inbox.</p>
        </div>
      </div>

      <div className='column third'>
        <div className='email-preview'>
          <div className='head'>
            <h3><SvgMail /> MAIL</h3>
            <h4>{subjectLine}</h4>
            <p>{previewLine}</p>
          </div>
          <div className='body'>
          </div>
        </div>
      </div>

    </div>
  </Step>
}

export default EmailSubject
