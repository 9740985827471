import Header from './Header'
import PageContainer from './PageContainer'
import SideBar from './SideBar'
import Loading from '../../Shared/components/Loading/Loading'
import TemplatePicker from './TemplatePicker'
import Button from 'Shared/components/Button'
import { useWindowSize } from 'usehooks-ts'

import './Builder.scoped.scss'

const Builder = ({campaignId, emailTemplateId}) => {
  const act = useAct()
  const [dataLoaded, setDataLoaded] = useState(false)
  const { width = 0 } = useWindowSize()


  const loaded = useSel((s) => 
    (s.emailTemplate.id || !emailTemplateId) &&
    (s.campaign.id || !campaignId) && 
      s.currentUser.id && 
      s.emailBuilder.blockTemplates && 
      dataLoaded
  )

  const emailTemplateName = useSel((s) => s.campaign.emailBlast.emailTemplate && s.campaign.emailBlast.emailTemplate.name)

  useEffect(() => {
    if(emailTemplateId) {
      act.emailBuilder.upsert({editingTemplate: true})
      act.emailTemplate.load(emailTemplateId)
    } else {
      act.campaign.load(campaignId)
    }
    act.campaignBuilder.loadMergeTags().
      then(() => act.user.loadMe()).
      then(() => act.emailBuilder.loadBlockTemplates()).
      then(() => setDataLoaded(true))
  }, [])

  if(!loaded) {
    return <Loading text="Loading" />
  }

  const showTemplatePicker = () => {
    act.emailBuilder.upsert({ switchingTemplate: true })
  }

  const ChangeTemplate = () => {
    if (emailTemplateId) return null
    return <>
      <p>
        Current template:&nbsp;
        <strong>{emailTemplateName}</strong>
      </p>
      <Button primary onClick={showTemplatePicker}>Change template</Button>
    </>
  }

  return (
    <div id="email-builder">
      <TemplatePicker />
      <Header />
      <section className="main">
        { (width > 1286 || width <= 981) &&
          <div className="left">
            <ChangeTemplate />
          </div>
        }
        <div className="page">
          <PageContainer />
        </div>
        <div className="right">
          <SideBar />
          { width > 981 && width <= 1286 &&
            <div className="margin-left margin-right">
              <ChangeTemplate />
            </div>
          }
        </div>
      </section>
    </div>
  )
}

export default Builder
