import './MergeTagList.scoped.scss'

export default ({ insert, mergeTags, close }) => {
  const [filterCategory, setFilterCategory] = useState('All content')
  const [showAll, setShowAll] = useState(false)
  const [search, setSearch] = useState('')

  const chooseTag = (e, label) => {
    e.nativeEvent.stopImmediatePropagation()
    insert(label)
    close()
  }

  let tagList
  const allTags = Object.values(mergeTags).reduce((acc, val) => acc.concat(val || []), [])

  if (search !== '') {
    const searchTags = allTags.filter((tag) =>
      tag.label.toLowerCase().includes(search.toLowerCase())
    )

    tagList = (
      <div className="merge-tag-list">
        <div className="search-result-list">
          <div className="heading">Search results</div>
          <ul>
            {searchTags.map((mergeTag, index) => (
              <li
                key={index}
                onClick={(e) => (!mergeTag.disabled ? chooseTag(e, mergeTag.label) : '')}
                className={`${mergeTag.disabled ? 'disabled' : ''}`}
                title={`${mergeTag.disabled ? 'Contact support to enable' : ''}`}
              >
                <span className="merge-tag">{mergeTag.label}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  } else {
    mergeTags = Object.assign({ 'All content': allTags }, mergeTags)
    let filteredTags = mergeTags[filterCategory] || [] // Handle when no tags present
    const showMoreLink = filteredTags.length > 15 && !showAll
    if (!showAll) filteredTags = filteredTags.slice(0, 15)

    tagList = (
      <div className="merge-tag-list">
        <div className="category-list">
          <div className="heading">Category</div>
          <ul>
            {Object.keys(mergeTags).map((category, index) => (
              <li
                key={index}
                className={category === filterCategory ? 'active' : null}
                onClick={function () {
                  setShowAll(false)
                  return setFilterCategory(category)
                }}
              >
                {category}
              </li>
            ))}
          </ul>
        </div>

        <div className="tag-list">
          <div className="heading">Fields</div>
          <ul>
            {filteredTags.map((mergeTag, index) => (
              <li
                key={index}
                onClick={(e) => (!mergeTag.disabled ? chooseTag(e, mergeTag.label) : '')}
                className={`${mergeTag.disabled ? 'disabled' : ''}`}
                title={`${mergeTag.disabled ? 'Contact support to enable' : ''}`}
              >
                <span className="merge-tag">{mergeTag.label}</span>
              </li>
            ))}
          </ul>
          {showMoreLink && (
            <div
              className="more"
              onClick={function (e) {
                e.nativeEvent.stopImmediatePropagation()
                return setShowAll(true)
              }}
            >
              More
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="popup popup-list search-wrapper">
      <div className="search">
        <input
          type="text"
          placeholder={'Search...'}
          onChange={function (e) {
            setShowAll(false)
            return setSearch(e.target.value || '')
          }}
        />
      </div>
      {tagList}
    </div>
  )
}
