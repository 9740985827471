import './CustomFields.scoped.scss'
import ActivateWidget from './Widgets/ActivateWidget'
import VerifyLinkWidget from './Widgets/VerifyLinkWidget'
import MembershipCardWidget from './Widgets/MembershipCardWidget'
import Boxes from 'Shared/components/Boxes'

const WIDGETS = {
  activate: <ActivateWidget />,
  verify: <VerifyLinkWidget />,
  membership_card: <MembershipCardWidget />,
}

const Widgets = ({ widgets }) => {
  return (
    <>
      {widgets.map((widget) => (
        <Boxes
          id={widget}
          sizes={['full-width']}
          boxes={[WIDGETS[widget]]}
          containerClass={'gutter-bottom'}
        />
      ))}
    </>
  )
}

export default Widgets
