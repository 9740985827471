import './SimplePaginator.scoped.scss'

export default function SimplePaginator({ totalPages, currentPage, onChange, labels, classes = '' }) {
  const first = currentPage === 1
  const last = currentPage === totalPages

  return (
    <div className={`box ${classes}`}>
      <button onClick={() => !first && onChange(currentPage - 1)}>
        <SvgIconsArrowLeft variant={first ? 'light' : 'dark'} />
      </button>
      <span className="label">{labels[currentPage - 1]}</span>
      <button onClick={() => !last && onChange(currentPage + 1)}>
        <SvgIconsArrowRight variant={last ? 'light' : 'dark'} />
      </button>
    </div>
  )
}

