const TARGET_ACTIVITY_DEFAULT_DAYS = 30
const DATE_FORMAT = 'DD MMM YYYY'
const SMS_OPT_OUT_MESSAGE = 'Reply STOP to opt out'
const MAX_WHATSAPP_MESSAGE_LENGTH = 1024
const TABLET_SCREEN_WIDTH = 900
const MOBILE_SCREEN_WIDTH = 768
const LARGE_SCREEN_WIDTH = 1280

const QUESTION_LABELS = {
  'intro': 'Greeting',
  'instruction': 'Instruction',
  'multiple_choice': 'Select one', 
  'multiple_select': 'Select multiple',
  'default': 'Question {index}'
}

export { TARGET_ACTIVITY_DEFAULT_DAYS, DATE_FORMAT, SMS_OPT_OUT_MESSAGE, MAX_WHATSAPP_MESSAGE_LENGTH, MOBILE_SCREEN_WIDTH, TABLET_SCREEN_WIDTH, LARGE_SCREEN_WIDTH, QUESTION_LABELS }


