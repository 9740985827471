import restClient from 'Shared/hooks/restClient'

export default function StaticBlock({ block, blockTemplate, active }) {
  const [html, setHtml] = useState('<span>Loading...</span>')
  const campaignId = useSel(s => s.campaign.id)

  const renderBlock = useMemo(() => {
    function renderBlock(block) {
      restClient.post('/api/render/render_block', { campaignId, block, blockTemplate })
      .then(({ data }) => { setHtml(data.html) })
    }

    const dRenderBlock = _.debounce(renderBlock, 250)
    let firstCall = true

    // Remove lag on first load
    return (block) => {
      if (firstCall) {
        firstCall = false
        renderBlock(block)
      } else {
        dRenderBlock(block)
      }
    }
  }, [])

  useEffect(() => {
    renderBlock(block)
  }, [JSON.stringify(block?.parameters)])

  const memoHtml = useMemo(() => {
    return <div className='wipe-css'>
      <div className='foundation-container' dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  }, [html])

  if (active) return null

  return memoHtml
}

