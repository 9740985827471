import Description from 'SearchBuilder/components/Description'
import { CircularProgress } from '@material-ui/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Table from 'Shared/components/DataTable/Table'

export default function Details() {
  const act = useAct()
  const { showTargetTable } = useSel((s) => s.searchBuilder)
  const { id, searchCount, recalculating, status } = useSel((s) => s.search)
  const supportEmail = useSel((s) => s.currentUser.clientSupportEmail)

  const tableProps = {
    table: 'targets',
    scope: { searchId: id },
    language: { emptyTable: 'There are no matching targets', searchPlaceholder: 'Search by name' },
    onlyColumns: {
      'external_id': "Membership Number",
      'name': "Name",
      'phone_number': "Phone Number"
    }
  }

  function loadCount() {
    act.search.loadCount(id).then(({ data: { recalculating } }) => {
      if (recalculating) {
        setTimeout(() => {
          loadCount()
        }, 2500)
      }
    })
  }

  useEffect(() => {
    if (id && id !== 'new') {
      loadCount()
    }
  }, [id])

  return (
    <div className="step">
      <div className="section">
        <div className="box">
          <Description />

          <div className="divider"></div>

          <div className="list">
            <div className="item">
              <div className="icon purple">
                <SvgIconsPersonBlue />
              </div>
              <div className="content">
                <div className="big-number">
                  {recalculating ? <CircularProgress size={'18px'} /> : searchCount.toLocaleString('en-US')}
                </div>
                <div className="emphasis">
                  {recalculating
                    ? 'Your audience is being generated'
                    : 'People in the audience who match the selected criteria'}
                </div>
              </div>
            </div>
          </div>
          {status === 'error' && (
            <div className="error margin-top">
              Sorry, an error occurred while generating this search. Please get in touch
              with <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
            </div>
          )}

          {!recalculating && (
            <>
              <div className="divider"></div>
              <a
                className="button naked margin-top"
                onClick={act.searchBuilder.toggleTargetTable}
              >
                {showTargetTable
                  ? 'Hide people in the audience'
                  : 'View people in the audience'}
                <ExpandMoreIcon
                  style={{
                    transform: showTargetTable ? 'rotate(180deg)' : undefined,
                  }}
                  sx={{
                    transition: 'all .15s ease-out',
                  }}
                />
              </a>
              {showTargetTable && (
                <div className="datatable targets margin-top">
                  <Table {...tableProps} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
