import store from 'Shared/store'
import { Provider } from 'react-redux'
import Menu from './Menu'

function startText(campaignType) {
  if (campaignType === 'phone') return 'Start calling'
  if (campaignType === 'sms_conversations') return 'Start replying'

  return ''
}

export default function CampaignActions({ id, campaignType, slug }) {
  const isShareCampaign = _.includes(['phone', 'sms_conversations'], campaignType)

  return (
    <Provider store={store}>
      <div className="actions">
        <Menu campaignId={id} campaignType={campaignType} slug={slug} />
        { isShareCampaign && (
          <a className="button primary" href={`/campaigns/${slug}`}>
            { startText(campaignType) }
          </a>
        ) }
      </div>
    </Provider>
  )
}
