import useCountTargets from 'CampaignBuilder/components/Steps/Audience/useCountTargets'
import { MAX_WHATSAPP_MESSAGE_LENGTH } from 'Shared/constants'

const campaignKindMap = {
  'sms_blast': 'sms_blast',
  'sms_survey': 'sms_blast',
  'sms_conversations': 'sms_blast',
  'phone': 'phone',
  'email': 'email'
}

export default function useValidateCampaign() {
  const { searchId, campaignType, isWorkflowControlled, textMessageBlast, emailBlast } = useSel(s => s.campaign, _.isEqual)
  const { searchCount, status: searchStatus, recalculating } = useSel(s => s.search)
  const { renderedBody } = useSel(s => s.campaignBuilder, _.isEqual)
  const questions = useSel(s => {
    return _.values(s.questions.entities).filter(q => q.active)
  }) // applicable to scriptable campaigns
  const blockCount = useSel((s) => s.blocks.present.ids.length) // applicable to emails only
  const hasCalculatedRecipients = !recalculating && searchCount
  const targetCount = useCountTargets()

  function validate() {
    const errors = []

    // run search and audience validators if the campaign is not controlled by a workflow
    if (!isWorkflowControlled) {
      // search validators
      // - require a valid search
      // - not recalculating currently
      // - not in error state
      if (!searchId) {
        errors.push('Please pick an audience to launch this campaign.')
      } else {
        if (!hasCalculatedRecipients) {
          errors.push('Your audience is currently being generated. Please check back shortly.')
        }
        if (searchStatus === 'error') {
          errors.push('Your audience has an error. Please correct this or select another audience.')
        }
      }

      // audience count validator
      // campaigns require at least 1 person to send to
      if (searchId && (!targetCount || targetCount === 0)) {
        errors.push('Your audience is empty. Please select another audience.')
      }
    }

    // email validators
    if (campaignType === 'email') {
      if (!blockCount) {
        errors.push("You haven't designed an email yet.")
      }
      if (!emailBlast.emailSenderId) {
        errors.push("Please select a sender for this email.")
      }
      if (!emailBlast.subjectLine.subjectLine) {
        errors.push("Please add a subject to your email.")
      }
    }

    // whatsapp validators
    if (campaignType.includes('sms_') && textMessageBlast.kind === 'whatsapp') {
      if (!textMessageBlast.whatsappTemplateId) {
        errors.push("Please select a WhatsApp template, and create content for this campaign")
      }
      if (renderedBody?.length > MAX_WHATSAPP_MESSAGE_LENGTH) {
        errors.push(`Message length exeeds ${MAX_WHATSAPP_MESSAGE_LENGTH} characters`)
      }
    }

    // sms blast and conversations validators
    if ((campaignType === 'sms_blast' || campaignType === 'sms_conversations') && textMessageBlast.kind === 'sms') {
      if (!textMessageBlast.contentVariants[0].content.body) {
        errors.push('Please include content for your message.')
      }
    }

    // sms survey validators
    if (campaignType === 'sms_survey') {
      if (questions.length === 0) {
        errors.push('Please add at least one question to your survey.')
      }
    }

    return errors
  }

  const errors = validate()
  const isValid = errors.length === 0

  return [isValid, errors]
}
