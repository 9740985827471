import { ChromePicker } from 'react-color';
import './ColorField.scoped.scss'

const ColorField = ({color, onChange, label = 'Colour'}) => {
  const [pickerVisible, setPickerVisible] = useState(false)

  return (
    <div className="field color-field">
      <label>{label}</label>
      <div>
        <span
          className="color-field-swatch"
          style={{ backgroundColor: color }}
          onClick={() => setPickerVisible(!pickerVisible)}>&nbsp;</span>
        <input
          type="text"
          className="color-field"
          value={color}
          onChange={(e) => onChange(e.target.value)} />
      </div>
      {pickerVisible &&
        <div className="color-field-picker">
          <ChromePicker disableAlpha color={color} onChange={(col) => onChange(col.hex)} />
        </div>
      }
    </div>
  )
}

export default ColorField
