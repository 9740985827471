import './QuestionStats.scoped.scss'

export default function QuestionStats({ surveyId }) {

  const questions = useSel((s) => {
    const questions = _.values(s.questions.entities).filter(
      (q) => ['multiple_choice', 'multiple_select'].includes(q.questionType) && q.active === true
    )

    return _.orderBy(questions, 'digitOrder')
  })

  var i = 0

  return (
    <div>
      {questions.map(({ id, questionType }) => {
        if (_.includes(['text', 'multiple_choice'], questionType)) i++
        return <Question id={id} key={id} index={i} />
      })}
    </div>
  )
}

function Question({ id, index }) {
  const { question, digitOrder } = useSel((s) => s.questions.entities[id])
  const answerIds = useSel((s) =>
    _.orderBy(
      _.values(s.answers.entities).filter((a) => a.questionId === Number(id)),
      'digitOrder'
    ).map((a) => a.id)
  )

  return (
    <div className="question-box">
      <div className="box-heading">
        <h3>
          <span className="dark-grey">#{index}:</span> {question}
        </h3>
      </div>
      {answerIds.map((id) => (
        <Answer id={id} key={id} />
      ))}
    </div>
  )
}

function Answer({ id }) {
  const { answer, digitOrder, answerCount } = useSel((s) => s.answers.entities[id])

  return (
    <div className="full-width-row">
      <div className="answer">
        <div className="percentage answer-number">{digitOrder}.</div>
        <div className="text">{answer}</div>
        <div className="count">{answerCount}</div>
      </div>
    </div>
  )
}

