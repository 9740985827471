import useOnScreen from 'Shared/hooks/useOnScreen'
import './EmailPreview.scoped.scss'
import MobileDesktopToggle from './MobileDesktopToggle/MobileDesktopToggle'
import { ordinal } from '../functions/util'

const formatDate = (date) => {
  const day = ordinal(date.getDate())
  const month = date.toLocaleString('default', { month: 'long' })
  const year = date.getFullYear()
  return `${day} ${month} ${year}`
}

const EmailPreview = ({
  html,
  subjectLine,
  internalWidth = 966,
  internalHeight = 768,
  showHeader = false,
  senderName = '',
  searchName = '',
  inline = false,
  maxHeight = false,
  autoHeight = false,
  noContainerHeight = false
}) => {
  const iframeRef = useRef(null)
  const [scale, setScale] = useState(0.1)
  const containerRef = useRef(null)
  const onScreen = useOnScreen(containerRef)
  const [display, setDisplay] = useState(() => {
    return window.innerWidth < 768 ? 'mobile' : 'desktop'
  })
  const [contentHeight, setContentHeight] = useState(internalHeight)

  const setHeight = () => {
    setContentHeight(iframeRef.current?.contentWindow.document.body.scrollHeight)
  }

  const measure = () => {
    setScale(containerRef.current.parentNode.offsetWidth / internalWidth)

    setHeight()
    setTimeout(() => {
      // sometimes the iframe height isn't correct until a render loop
      setHeight()
    }, 250)
  }

  useEffect(() => {
    window.addEventListener("resize", measure)
    measure()

    return () => {
      window.removeEventListener("resize", measure)
    };
  }, [])

  useEffect(() => {
    measure()
  }, [onScreen])

  useEffect(() => {
    const doc = iframeRef.current?.contentWindow.document
    doc.open()
    doc.write(html)
    doc.close()
  }, [html])

  const calculateMobileAdjustedScale = () => {
    if(!containerRef.current) return 0;
    if (display === 'mobile' && window.innerWidth < 900) {
      const baseScale = containerRef.current.parentNode.offsetWidth * 0.0007;
      const screenWidthRatio = window.innerWidth / 900;
      const scaleAdjustment = Math.pow(screenWidthRatio, -0.4); // For adjusting the scale to be more accurate on smaller screens
      return baseScale * scaleAdjustment;
    }
    return 0;
  };
  const mobileAdjustedScale = calculateMobileAdjustedScale();
  const widthScale = display === 'desktop' ? 1 : 0.5

  const iframeWidth = internalWidth * widthScale * (scale + mobileAdjustedScale)
  const iframeHeight = Math.round(contentHeight * (scale + mobileAdjustedScale))

  const left = containerRef.current ? (containerRef.current.parentNode.offsetWidth - iframeWidth) / 2 : 0
  window.container = containerRef.current
  console.log('iframeWidth', iframeWidth)

  const iframeStyle = {
    width: inline ? '100%' : `${internalWidth * widthScale}px`,
    height: `${contentHeight}px`,
    flexShrink: 0,
    position: (inline || autoHeight) ? 'relative' : "absolute",
    left,
    transformOrigin: '0% 0%',
    transform: inline ? 'auto' : `scale(${scale + mobileAdjustedScale})`
  }

  // container height is set to the computed height of the scaled iframe to prevent vertical scrolling since
  // scaling down the iframe doesn't affect scrolling
  const containerStyle = {
    height: noContainerHeight ? 'auto' : `${iframeHeight}px`,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  }
  
  return (
    <div style={containerStyle} ref={containerRef}>
      {
        showHeader &&
        <div className="box email-preview-header">
          <div>
            <p>From: <strong>{senderName}</strong></p>
            <p>Recieved: <strong>{formatDate(new Date())}</strong></p>
          </div>
          <p>To: <strong>{searchName}</strong></p>
          <p>Subject: <strong>{subjectLine}</strong></p>
        </div>
      }
      {!inline && <MobileDesktopToggle value={display} onChange={(value) => {setDisplay(value); measure();}} />}
      <iframe ref={iframeRef} onLoad={measure} style={iframeStyle}></iframe>
    </div>
  )
}

export default EmailPreview
