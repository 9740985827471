import FilterParameter from './FilterParameter'
import './Filter.scoped.scss'

export default ({ id }) => {
  const {
    not,
    filter: filterName,
    parameters,
    targetIds,
  } = useSel((s) => s.rules.entities[id])
  const availableFilters = useSel((s) => s.availableFilters)
  const filter = availableFilters[filterName]

  if (!filter) return null

  return (
    <div className="filter">
      {/*<div className='count'>{ targetIds?.length || 0 } targets</div>*/}
      <div className="components">
        {filter.presentation.layout.map(({ type, text, slug }) => {
          if (type === 'label') {
            return <p className="label bold">{text}</p>
          } else if (type === 'parameter') {
            return <FilterParameter id={id} name={_.camelCase(slug)} />
          }
        })}
      </div>
    </div>
  )
}
