import './VariantStats.scoped.scss'
import BackdropModal from 'Shared/components/BackdropModal/BackdropModal';
import ServerWaitButton from 'Shared/components/ServerWaitButton';
import SmsPreview from 'CampaignBuilder/components/Steps/SmsPreview';

const VariantStats = ({ sendPhase, variants }) => {
  const [widerVariantId, setWiderVariantId] = useState(null)
  const contentVariants = useSel(s => s.campaign?.textMessageBlast?.contentVariants || [])
  const [showConfirmation, setShowConfirmation] = useState(false)
  const currentSendPhase = useSel(s => s.campaign?.currentSendPhase)
  const error = useSel((s) => s.local.error)
  const orderedVariants = _.orderBy(variants, 'id')
  const act = useAct()
  const maxClickRate = _.maxBy(variants, variant => parseFloat(variant.clickRate)).clickRate
  const maxReplyRate = _.maxBy(variants, variant => parseFloat(variant.replyRate)).replyRate

  function setCurrentVariant(id) {
    act.local.upsert({ currentContentVariantId: id })
  }

  function closeModal() {
    setWiderVariantId(null)
    setShowConfirmation(false)
  }

  function confirmSendWider() {
    return act.campaignBuilder.sendTextMessageBlastWider(widerVariantId)
    .then(() => {
      setShowConfirmation(true)
    })
    .catch(() => {
      act.local.upsert({ error: 'An error occurred while sending the wider version' })
      closeModal()
    })
  }

  function redirect() {
    window.location.href = '/admin/campaigns'
  }

  const isFinal = variants.length === 1

  return (
    <div className="variant-list">
      {error && <div className="error">{error}</div>}
      <table>
        <thead>
          <tr>
            <th className="variant-header"></th>
            <th className="variant-header">Texts sent</th>
            <th className="variant-header">Click rate</th>
            <th className="variant-header">Reply rate</th>
            <th className="variant-header">Unsubscribes</th>
            <th className="variant-header"></th>
          </tr>
        </thead>
        <tbody>
          {orderedVariants.map((variant, index) => (
            <tr key={index} className="variant-row">
              <td className="left-border-radius">
                <h3 className="variant-name" onClick={() => setCurrentVariant(variant.id)}>
                  { isFinal ? "Final Version" : `Version ${index + 1}` }
                </h3>
                { variant.sentWider && <p className="sent-wider">Sent wider</p>}
              </td>
              <td>
                <h1 className={`stat-label`}>{variant.textMessagesCount.toLocaleString()}</h1>
              </td>
              <td>
                <h1 className={`stat-label ${variant.clickRate > 0 && variant.clickRate === maxClickRate ? 'stat-highlight' : ''}`}>{variant.clickRate}%</h1>
              </td>
              <td>
                <h1 className={`stat-label ${variant.replyRate > 0 && variant.replyRate === maxReplyRate ? 'stat-highlight' : ''}`}>{variant.replyRate}%</h1>
              </td>
              <td className="right-border-radius">
                <h1 className={`stat-label`}>{variant.unsubscribeCount.toLocaleString()}</h1>
              </td>
              <td>
                { !isFinal && String(currentSendPhase) === String(sendPhase) && <button className='button primary' onClick={() => setWiderVariantId(variant.id)}>Send</button> }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <BackdropModal open={!!widerVariantId} title={"Send wider"} onCancel={closeModal} maxWidth='md'>
        { !showConfirmation && (
        <div className='row'>
          <div className='column half'>
            <p>Send this version to the rest of the audience</p>
            <ServerWaitButton
              onClick={confirmSendWider}
              className="button primary margin-right"
            >
              Send
            </ServerWaitButton>
          </div>
          <div className='column half'>
            <SmsPreview content={contentVariants.find(cv => cv.id === widerVariantId)?.content || {}} short />
          </div>
        </div>
        )}
        { showConfirmation && (
          <div>
            <h3>This version will be sent to the rest of your campaign's audience shortly.</h3>
            <button className="button primary margin-right" onClick={closeModal}>Close window</button>
            <button className="button secondary" onClick={redirect}>Back to campaigns list</button>
          </div>
        )}
      </BackdropModal>
    </div>
  );
};

export default VariantStats;
