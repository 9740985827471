import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'
import Loading from '../../Shared/components/Loading/Loading'
import './PreviewModal.scoped.scss'
import useEmailPreview from 'Shared/hooks/useEmailPreview'
import EmailPreview from 'Shared/components/EmailPreview'


const PreviewModal = ({ open, hide }) => {
  const [html, subjectLine, loading] = useEmailPreview([open])

  const iframeStyle = {
    border: '1px solid #999',
    width: '650px',
    height: '450px',
    padding: '20px',
    zIndex: 3000,
    position: 'relative'
  }

  return (
    <BackdropModal title="Email preview" open={open} onCancel={hide} tall scrollable={true}>
      <div className="main">
        {loading && <Loading text="Loading preview" />}
        {open && !loading && <EmailPreview html={html} subjectLine={subjectLine} iframeStyle={iframeStyle} maxHeight={false} autoHeight={true} />}
      </div>
    </BackdropModal>
  )
}

export default PreviewModal
