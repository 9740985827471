import './Header.scoped.scss'
import PreviewModal from './PreviewModal'
import EditableName from 'Shared/components/EditableName/EditableName'
import Undo from '../../../images/icons/Undo'
import Redo from '../../../images/icons/Redo'
import BackLink from 'Shared/components/BackLink'
import Button from 'Shared/components/Button'
import ServerWaitButton from '../../Shared/components/ServerWaitButton'

const Header = () => {
  const act = useAct()
  const {
    id: campaignId,
    name: campaignName,
    search: { name: searchName, targetCount } = {},
    adminUrl,
  } = useSel((s) => s.campaign, _.isEqual)
  const emailTemplate = useSel((s) => s.emailTemplate)
  const emailTemplateName = emailTemplate?.name
  const emailTemplateId = emailTemplate?.id
  const emailTemplateOrganisationId = emailTemplate?.organisationId
  const editingTemplate = useSel((s) => !!s.emailBuilder.editingTemplate)
  const dirty = useSel((s) => s.emailBuilder.dirty)
  const [canUndo, canRedo] = useSel((s) => [
    s.blocks.past.length > 1,
    s.blocks.future.length > 0,
  ])
  const [previewOpen, setPreviewOpen] = useState(false)
  const clientName = useSel((s) => s.currentUser.clientName)

  const persist = () => {
    if (editingTemplate) {
      return act.emailTemplate.persist()
    } else {
      return act.campaign.persist()
    }
  }

  const backToBuild = () => {
    return persist().then(() => {
      window.location.href = adminUrl
      return Promise.resolve(true)
    })
  }

  const backToTemplateList = () => {
    persist().then(() => (window.location.href = '/admin/settings/email_templates'))
  }

  const showPreview = () => {
    persist().then(() => setPreviewOpen(true))
  }

  const backLink = campaignId
    ? `/admin/campaigns/${campaignId}/build`
    : '/admin/settings/email_templates'

  return (
    <header>
      <div className="about">
        <div>
          <BackLink href={backLink} />{' '}
          <h1 className="no-margin-top no-margin-bottom">
            {editingTemplate && !!emailTemplateName ? (
              <EditableName
                modelName="email_template"
                name={emailTemplateName}
                onUpdate={(name) => act.emailTemplate.upsert({ name })}
                endpoint={`/api/email_templates/${emailTemplateId}`}
                noMarginBottom={true}
              />
            ) : (
              campaignName
            )}
          </h1>
        </div>
        {searchName && (
          <p>
            To <strong>{searchName}</strong> ({targetCount} recipients)
          </p>
        )}
        <p className="dark-grey italic save-status">
          {dirty ? "You've made changes." : 'All changes saved.'}
        </p>
        {editingTemplate &&
          <div className="toggle-current-organisation margin-top">
            <label className="align-center">
              <input
                type="checkbox"
                name="toggle_current_organisation"
                defaultChecked={!emailTemplateOrganisationId}
                onChange={(e) => {
                  act.emailTemplate.upsert({ setCurrentOrganisation: !e.target.checked })
                }}
              />
              &nbsp;Share with everyone in {clientName}
            </label>
          </div>}
      </div>

      <div className="actions">
        <Button
          disabled={!dirty}
          light
          primary
          onClick={persist}
          extraClass={'save-button'}
        >
          Save
        </Button>

        {editingTemplate && (
          <Button primary onClick={backToTemplateList}>
            Finish
          </Button>
        )}

        {!editingTemplate && (
          <>
            <Button secondary onClick={showPreview}>
              Preview
            </Button>
            <ServerWaitButton primary onClick={backToBuild}>
              Continue
            </ServerWaitButton>
          </>
        )}

        <div className="undo-redo">
          <Button
            tertiary
            onClick={act.history.undo}
            disabled={!canUndo}
            extraClass="undo"
          >
            <Undo />
          </Button>
          <Button
            tertiary
            onClick={act.history.redo}
            disabled={!canRedo}
            extraClass="redo"
          >
            <Redo />
          </Button>
        </div>
      </div>

      <PreviewModal open={previewOpen} hide={() => setPreviewOpen(false)} />
    </header>
  )
}

export default Header
