export default (dis, store, client) => {
  function load(id) {
    return client.get(`/api/email_templates/${id}`).then(({ data }) => {
      dis({ type: 'emailTemplate/upsert', payload: data })
      dis({ type: 'blocks/setAll', payload: data.emailBlastBlocks })
      dis({
        type: 'emailBuilder/upsert',
        payload: {
          headerHtmlRendered: data.headerHtmlRendered,
          footerHtmlRendered: data.footerHtmlRendered,
          hasUnsubscribeBlock: data.hasUnsubscribeBlock,
        },
      })
    })
  }

  function persist() {
    const { emailTemplate, blocks } = store.getState()

    const payload = {
      description: emailTemplate.description,
      emailBlastBlocks: blocks.present.ids.map((id) => blocks.present.entities[id]),
      setCurrentOrganisation: emailTemplate.setCurrentOrganisation,
    }

    return client
      .put(`/api/email_templates/${emailTemplate.id}`, { email_template: payload })
      .then(({ data: { emailTemplate } }) => {
        dis({ type: 'emailTemplate/upsert', payload: emailTemplate })
        dis({ type: '@@redux-undo/CLEAR_HISTORY' })
        dis({ type: 'emailBuilder/setDirty', payload: false })
      })
  }

  function upsert(payload) {
    dis({ type: 'emailTemplate/upsert', payload })
    dis({ type: 'emailBuilder/setDirty', payload: true })
  }

  return { load, persist, upsert }
}
