import './ScriptPreview.scoped.scss'
import ScriptPreviewStep from './ScriptPreviewStep'

export default function ScriptPreview () {
  const enableOptOut = useSel(s => s.campaign.textMessageBlast && s.campaign.textMessageBlast.enableOptOut)

  const questions = useSel(s => {
    const questions = _.values(s.questions.entities).filter(q => q.active)

    return _.orderBy(questions, 'digitOrder')
  })

  let i = 0

  return <div className='script-preview margin-bottom'>
    { questions.length === 0 && <p>You're not asking any questions.</p> }
    { questions.map(({ id, questionType }) => {
      if (_.includes(['text', 'multiple_choice', 'multiple_select', 'send_sms'], questionType)) i++

      return <ScriptPreviewStep questionId={id} index={i} key={id} includeOptOut={i == 1 && enableOptOut} />
    }) }
  </div>
}
