import React, { useCallback, useEffect, useMemo } from 'react'
import restClient from 'Shared/hooks/restClient'
import BackdropModal from 'Shared/components/BackdropModal/BackdropModal'
import userCan from 'Shared/functions/userCan'
import CampaignName from 'Shared/components/CampaignName'
import ShareCampaign from 'Campaigns/ShareCampaign/components/ShareCampaign'
import { ManageMenuItem } from '..'
import { ManageMenuPropsHookResult } from '../hooks'
import RefreshTargets from 'Campaigns/ManageCampaign/components/RefreshTargets'
import useExtraActions from 'CampaignOverview/useAct'
import Modal from 'Shared/components/Modal/Modal'
import CopyToClipboard from 'Shared/components/CopyToClipboard'
import { set } from 'lodash'

export function useCampaignActions(campaign) {
  if (!campaign?.id) {
    return []
  }
  return [
    {
      type: 'menu',
      props: {
        type: 'campaign',
        target: campaign,
      },
    },
  ]
}
export function useCampaignMenuProps(campaign: any): ManageMenuPropsHookResult {
  const act = useAct()
  const dis = useDis()
  const isShareCampaign = useMemo(() => {
    return _.includes(['phone', 'sms_conversations'], campaign?.campaignType)
  }, [campaign?.campaignType])
  const [duplicateCampaignModalVisible, setDuplicateCampaignModalVisible] =
    useState(false)
  const isEditCampaign = useMemo(() => {
    return (
      (_.includes(['phone'], campaign.campaignType) && campaign.status !== 'closed') ||
      (_.includes(['sms_conversations'], campaign.campaignType) &&
        _.includes(['launching', 'live', 'sent'], campaign.status))
    )
  }, [campaign?.campaignType])

  const isPauseAvailable = useMemo(() => {
    return _.includes(['phone'], campaign?.campaignType) && campaign?.status == 'live'
  }, [campaign?.campaignType, campaign?.status])

  const isUnpauseAvailable = useMemo(() => {
    return _.includes(['phone'], campaign?.campaignType) && campaign?.status == 'paused'
  }, [campaign?.campaignType, campaign?.status])
  const [refreshModalOpen, setRefreshModalOpen] = useState(false)

  const extraActions = useExtraActions()
  const [exportPassword, setExportPassword] = useState(null)

  const doExport = useCallback(() => {
    extraActions.campaignStats.emailExport().then((password) => {
      setExportPassword(password)
    })
  }, [extraActions.campaignStats])

  const items = useMemo(() => {
    if (!campaign?.id) {
      return []
    }
    const handleCloseCampaignClick = () => {
      if (confirm('Are you sure you want to close this campaign?')) {
        restClient.post(`/api/campaigns/${campaign.id}/close`).then(() => {
          location.reload()
        })
      }
    }

    const handleReopenCampaignClick = () => {
      restClient.post(`/api/campaigns/${campaign.id}/launch`).then(() => {
        window.location.href = '/admin/campaigns'
      })
    }

    const handleArchiveCampaignClick = () => {
      restClient.delete(`/api/campaigns/${campaign.id}`).then(() => {
        window.location.href = '/admin/campaigns'
      })
    }

    const handleDuplicateCampaignClick = () => {
      dis({
        type: 'createCampaign/upsert',
        payload: { type: campaign.campaignType, cloneFromId: campaign.id },
      })
      setDuplicateCampaignModalVisible(true)
    }

    function editCampaign() {
      window.location.href = `/admin/campaigns/${campaign.id}/build`
    }

    const pauseCampaign = () => {
      if (confirm('Are you sure you want to pause this campaign?')) {
        restClient.post(`/api/campaigns/${campaign.id}/pause`).then(() => {
          location.reload()
        })
      }
    }
    const unpauseCampaign = () => {
      if (confirm('Are you sure you want to unpause this campaign?')) {
        restClient.post(`/api/campaigns/${campaign.id}/unpause`).then(() => {
          location.reload()
        })
      }
    }

    const items: ManageMenuItem[] = []
    if (isShareCampaign) {
      items.push({
        title: 'Share your campaign',
        onClick: act.shareCampaign.open,
        className: 'name',
      })
    }
    if (isEditCampaign) {
      items.push({
        title: 'Edit campaign',
        onClick: editCampaign,
        className: 'name',
      })
    }
    if (isPauseAvailable) {
      items.push({
        title: 'Pause campaign',
        onClick: pauseCampaign,
        className: 'name',
      })
    }
    if (isUnpauseAvailable) {
      items.push({
        title: 'Unpause campaign',
        onClick: unpauseCampaign,
        className: 'name',
      })
    }
    if (userCan('create_campaigns')) {
      items.push({
        title: 'Duplicate campaign',
        onClick: handleDuplicateCampaignClick,
      })
    }
    items.push({
      title: 'Refresh audience',
      onClick: () => {
        setRefreshModalOpen(true)
      },
    })
    if (campaign.campaignType === 'email') {
      items.push({
        title: 'Export results',
        onClick: () => {
          doExport()
        },
      })
    }
    if (campaign.status == 'closed') {
      if (campaign.campaignType === 'phone') {
        items.push({
          title: 'Reopen campaign',
          confirmDescription:
            "Are you sure you want to reopen this campaign? It may have a different phone number, and the campaign's search will be re-run, which may pull in new people to contact, and remove anyone no longer in the search.",
          onClick: handleReopenCampaignClick,
        })
      }
      items.push({
        title: 'Archive campaign',
        confirmValue: 'delete',
        className: 'danger',
        onClick: handleArchiveCampaignClick,
      })
    } else {
      items.push({
        title: 'Close campaign',
        className: 'danger',
        onClick: handleCloseCampaignClick,
      })
    }
    return items
  }, [campaign, isShareCampaign, isEditCampaign, doExport])

  const childrenComponents = useMemo(() => {
    if (!campaign?.id) {
      return []
    }
    const children = [
      <BackdropModal
        title="Duplicate Campaign"
        open={duplicateCampaignModalVisible}
        onCancel={() => setDuplicateCampaignModalVisible(false)}
        key="duplicate_campaign_backdrop"
      >
        <div className="wizard">
          <div className="step">
            <CampaignName
              type={campaign?.campaignType}
              askAttachSearch
              onCancel={() => setDuplicateCampaignModalVisible(false)}
            />
          </div>
        </div>
      </BackdropModal>,
    ]
    if (refreshModalOpen) {
      children.push(
        <div className="refresh-targets-modal" key="refresh-targets-modal">
          <RefreshTargets
            campaignId={campaign.id}
            open={refreshModalOpen}
            close={() => setRefreshModalOpen(false)}
          />
        </div>
      )
    }
    if (isShareCampaign) {
      children.push(
        <ShareCampaign
          key="share_campaign_el"
          campaignId={campaign.id}
          campaignUrl={campaign.callUrl}
          showStart={false}
          startText="Start calling"
          slug={campaign.slug}
        />
      )
    }
    if (exportPassword) {
      children.push(
        <Modal
          key="export_modal"
          open={!!exportPassword}
          title="Export in progress"
          onCancel={() => setExportPassword(null)}
        >
          The campaign is exporting. You'll receive an email when it's done. Use
          this password to open it: &nbsp;
          <CopyToClipboard
            text={`${exportPassword} (click to copy)`}
            url={exportPassword}
          />
        </Modal>
      )
    }
    return children
  }, [
    campaign,
    refreshModalOpen,
    duplicateCampaignModalVisible,
    setDuplicateCampaignModalVisible,
    isShareCampaign,
    exportPassword,
  ])
  return [items, childrenComponents]
}
