import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import pluralize from 'pluralize'
import { useEffect } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import './EntitySelector.scoped.scss'

const EntitySelector = ({ entity, value, onChange, placeholder = "Search", scope = null, defaultSearch = null, additionalColumns = []}) => {
  const [options, setOptions] = useState(null)
  const client = applyCaseMiddleware(axios.create())

  useEffect(() => {
    if (value) {
      client.get(`/api/${_.snakeCase(pluralize(entity))}/${value}`)
        .then(({ data: { id, name } }) => setOptions([{ value: id, label: name }]))
    } else {
      setOptions([])
    }

    if(defaultSearch !== null) {
      search(defaultSearch, true)
    }
  }, [])

  const search = (text, autoSelectFirst = false) => {
    client.get(`/api/${_.snakeCase(pluralize(entity))}/search${scope ? `?scope=${scope}` : ''}`, { params: { query: text } })
      .then(({ data }) => {
        setOptions(data)
        if(autoSelectFirst && data[0]) {
          select(data[0].value)
        }
      })
  }

  const select = (id) => {
    onChange(id)
  }

  const appendColumns = (option) => {
    if (additionalColumns.length === 0) {
      return ''
    } else {
      const parts = additionalColumns.map(column => option[column]).join(', ')
      return `(${parts})`
    }
  }

  if (!options) { return <div>Loading...</div> }

  return <div className='entity-selector'>
    <div className='search'>
      <SvgIconsSearch className="icon"/>
      <input type='text' placeholder={placeholder} onChange={(e) => search(e.target.value)} />
    </div>
    <div className='results'>
      { options.length > 0 &&
        <Scrollbars style={{ width: '100%', height: '200px' }}>
          { options.map(option => {
            return <div className={`result ${option.value === value ? 'selected' : ''}`} onClick={() => select(option.value)} key={option.value}>
              <p>{ option.label } { appendColumns(option) }</p>
            </div>
          }) }
        </Scrollbars>
      }
      { options.length === 0 && <p>Enter a search above.</p> }
    </div>
  </div>
}

export default EntitySelector
