import restClient from 'Shared/hooks/restClient'

const useEmailPreview = (dependencies = [], overrideCampaignId = null, overrideEmailBlastId = null) => {
  const campaignId = useSel(s => overrideCampaignId || s.campaign.id)
  const emailBlastId = useSel(s => overrideEmailBlastId || s.campaign.emailBlast.id)
  const previewPath = `/api/campaigns/${campaignId}/email_blasts/${emailBlastId}/preview`

  const [loading, setLoading] = useState(false)
  const [html, setHtml] = useState('')
  const [subjectLine, setSubjectLine] = useState('')

  useEffect(() => {
    if (!campaignId || !emailBlastId) return

    setLoading(true)
    restClient.get(previewPath)
      .then(({ data }) => {
        setHtml(data.html)
        setSubjectLine(data.subjectLine)
        setLoading(false)
      })
  }, [...dependencies, campaignId, emailBlastId])

  return [html, subjectLine, loading]
}

export default useEmailPreview
