import campaignBuilderActions from 'Shared/hooks/campaignBuilder'
import scriptBuilderActions from 'Shared/ScriptEditor/hooks/scriptBuilder'
import countSms from 'lib/sms-length'

export default (dis, store, client) => {
  const campaignBuilder = campaignBuilderActions(dis, store, client)
  const scriptBuilder = scriptBuilderActions(dis, store, client)

  function load(id) {
    return client.get(`/api/campaigns/${id}`).then(({ data }) => {
      dis({
        type: 'campaign/upsert',
        payload: { ..._.omit(data, 'emailBlast.emailBlastBlocks') },
      })
      if (data.emailBlast && data.emailBlast.emailBlastBlocks) {
        dis({ type: 'blocks/setAll', payload: data.emailBlast.emailBlastBlocks })
        dis({
          type: 'emailBuilder/upsert',
          payload: {
            markup: data.emailBlast.emailTemplate?.markup,
            headerHtmlRendered: data.emailBlast.emailTemplate?.headerHtmlRendered,
            footerHtmlRendered: data.emailBlast.emailTemplate?.footerHtmlRendered,
            hasUnsubscribeBlock: data.emailBlast.emailTemplate?.hasUnsubscribeBlock,
          },
        })
      }
    })
  }

  function saveCampaign(id, payload, options = {}) {
    return client
      .put(`/api/campaigns/${id}`, { campaign: payload })
      .then(({ data: { campaign } }) => {
        dis({
          type: 'campaign/upsert',
          payload: {
            ..._.omit(campaign, 'emailBlast.emailBlastBlocks'),
            lastSavedAt: Date.now(),
            search: campaign.search,
          },
        })

        if (campaign.emailBlast && campaign.emailBlast.emailBlastBlocks) {
          dis({ type: 'blocks/setAll', payload: campaign.emailBlast.emailBlastBlocks })
          dis({ type: '@@redux-undo/CLEAR_HISTORY' })
          dis({ type: 'emailBuilder/setDirty', payload: false })
          dis({ type: 'emailBuilder/upsert', payload: { errors: null } })
        }
        return campaign
      })
      .then((campaign) => {
        if (campaign.surveyId && options.reloadSurvey) {
          return scriptBuilder.load(campaign.surveyId)
        } else {
          return true
        }
      })
      .catch((error) => {
        if (error.response.status === 422 && error.response.data.errors) {
          dis({ type: 'emailBuilder/upsert', payload: { errors: error.response.data.errors } })
        }
      })
  }

  function persist(options = {}) {
    const { campaign, blocks } = store.getState()
    const {
      id,
      name,
      instructions,
      textMessageBlast,
      emailBlast,
      searchId,
      autoRefreshSearch,
      effortId,
      deadline,
      settings,
      outboundNumberId,
      subscriptionTopic
    } = campaign

    const payload = { instructions, searchId, autoRefreshSearch, effortId, outboundNumberId, subscriptionTopic }
    if (deadline) {
      payload.deadline = deadline
    }
    if (Object.keys(settings).length > 0) {
      payload.settings = settings
    }

    if (textMessageBlast && textMessageBlast.id !== null) {
      payload.textMessageBlast = {
        name: `${name} - SMS Blast`,
        senderName: textMessageBlast.senderName,
        senderType: textMessageBlast.senderType,
        enableOptOut: textMessageBlast.enableOptOut,
        whatsappTemplateId: textMessageBlast.whatsappTemplateId,
        interpolations: textMessageBlast.interpolations,
        encoding: countSms(textMessageBlast.body).encoding,
        contentVariants: textMessageBlast.contentVariants,
      }
    }

    if (emailBlast && emailBlast.id !== null) {
      payload.emailBlast = {
        name: `${name} - Email Blast`,
        replyTo: emailBlast.replyTo,
        emailSenderId: emailBlast.emailSenderId,
        emailTemplateId: emailBlast.emailTemplateId,
        isConstitutional: emailBlast.isConstitutional,
        senderName: emailBlast.senderName,
        subjectLine: {
          subjectLine: emailBlast.subjectLine.subjectLine,
          previewLine: emailBlast.subjectLine.previewLine,
        },
      }
      if (blocks !== null) {
        payload.emailBlast.emailBlastBlocks = blocks.present.ids.map(
          (id) => blocks.present.entities[id]
        )
      }
    }

    return saveCampaign(id, payload, options)
  }

  function launch() {
    const { campaign } = store.getState()
    const { id } = campaign
    return client.post(`/api/campaigns/${id}/launch`)
  }

  function send() {
    const { campaign } = store.getState()
    const { id, textMessageBlast } = campaign
    return client
      .post(`/api/campaigns/${id}/text_message_blasts/${textMessageBlast.id}/prepare`)
      .then(({ data: { redirectUrl } }) => {
        window.location = redirectUrl
      })
  }

  function updateSetting(key, value) {
    const {
      campaign: { settings },
    } = store.getState()
    return dis({
      type: 'campaign/upsert',
      payload: { settings: { ...settings, [key]: value } },
    })
  }

  function upsert(payload) {
    return dis({ type: 'campaign/upsert', payload })
  }

  function upsertTextMessageBlast(payload) {
    const {
      campaign: { textMessageBlast },
    } = store.getState()
    dis({
      type: 'campaign/upsert',
      payload: { textMessageBlast: { ...textMessageBlast, ...payload } },
    })
  }

  function addTextMessageBlastContentVariant() {
    const {
      campaign: { textMessageBlast },
    } = store.getState()
    const { contentVariants } = textMessageBlast
    const newId = `z${contentVariants.length + 1}${uuidv4()}`

    const newContentVariant = {
      id: newId,
      content: { body: '' },
    }
    dis({
      type: 'campaign/upsert',
      payload: {
        textMessageBlast: {
          ...textMessageBlast,
          contentVariants: [...contentVariants, newContentVariant],
        },
      },
    })
    dis({ type: 'local/upsert', payload: { currentContentVariantId: newId } })
  }

  function deleteTextMessageBlastContentVariant(contentVariantId) {
    const {
      campaign: { textMessageBlast },
    } = store.getState()
    const { contentVariants } = textMessageBlast
    const contentVariantsCopy = contentVariants.filter((cv) => cv.id !== contentVariantId)
    dis({ type: 'local/upsert', payload: { currentContentVariantId: contentVariantsCopy[0].id } })
    dis({
      type: 'campaign/upsert',
      payload: { textMessageBlast: { ...textMessageBlast, contentVariants: contentVariantsCopy } },
    })
  }

  function upsertTextMessageBlastContent(contentVariantId, content) {
    const {
      campaign: { textMessageBlast },
    } = store.getState()
    const { contentVariants } = textMessageBlast
    const contentVariantsCopy = contentVariants.map((cv) =>
      cv.id === contentVariantId ? { ...cv, content } : cv
    )
    dis({
      type: 'campaign/upsert',
      payload: { textMessageBlast: { ...textMessageBlast, contentVariants: contentVariantsCopy } },
    })

    campaignBuilder.renderTemplate()
  }

  function upsertEmailBlast(payload) {
    const {
      campaign: { emailBlast },
    } = store.getState()
    dis({
      type: 'campaign/upsert',
      payload: { emailBlast: { ...emailBlast, ...payload } },
    })
  }

  function upsertSubjectLine(subjectPayload) {
    const {
      campaign: { emailBlast },
    } = store.getState()
    const { subjectLine } = emailBlast
    upsertEmailBlast({})
    const payload = {
      emailBlast: {
        ...emailBlast,
        subjectLine: {
          ...subjectLine,
          ...subjectPayload,
        },
      },
    }
    dis({ type: 'campaign/upsert', payload })
  }

  function addGroup(groupId) {
    const {
      campaign: { id },
    } = store.getState()
    return client.post(`/api/campaigns/${id}/groups`, { groupId }).then(({ data }) => {
      dis({ type: 'campaign/upsert', payload: { groups: data } })
    })
  }

  function removeGroup(groupId) {
    const {
      campaign: { id },
    } = store.getState()
    return client.delete(`/api/campaigns/${id}/groups/${groupId}`).then(({ data }) => {
      dis({ type: 'campaign/upsert', payload: { groups: data } })
    })
  }

  function setDeadline(deadline) {
    const { campaign } = store.getState()
    dis({ type: 'campaign/upsert', payload: { deadline: deadline } })
  }

  function archive() {
    const {
      campaign: { id },
    } = store.getState()
    return client
      .delete(`/api/campaigns/${id}`)
      .then(() => (window.location.href = '/admin/campaigns'))
  }

  function refreshTargets(id, dryRun) {
    return client.post(
      `/api/campaigns/${id}/targets/refresh${dryRun ? '?dry_run=true' : ''}`
    )
  }

  function targetsStatus(id) {
    return client.get(`/api/campaigns/${id}/targets/status`)
  }

  return {
    addGroup,
    launch,
    load,
    persist,
    removeGroup,
    archive,
    send,
    refreshTargets,
    targetsStatus,
    updateSetting,
    upsert,
    upsertTextMessageBlast,
    addTextMessageBlastContentVariant,
    deleteTextMessageBlastContentVariant,
    upsertTextMessageBlastContent,
    upsertEmailBlast,
    upsertSubjectLine,
    setDeadline,
      }
}
