import { add } from 'lodash'
import './NewBlock.scoped.scss'
import { startCase } from 'Shared/functions/util'

const ICONS = {
  'button': <SvgIconsButton />,
  'heading': <SvgIconsHeading />,
  'ordered_list': <SvgIconsOrderedList />,
  'unordered_list': <SvgIconsUnorderedList />,
  'image': <SvgIconsImage />,
  'paragraph': <SvgIconsAlignLeft />,
  'quote': <SvgIconsQuote />,
  'spacer': <SvgIconsSpacer />,
  'divider': <SvgIconsDivider />,
  'table': <SvgIconsHtml />,
  'html': <SvgIconsHtml />,
}

const NAME_REPLACEMENTS = {
  'unordered_list': 'list'
}

const NewBlock = ({ canClose = true, handleClick, onClose = null }) => {
  const blockTemplates = useSel((s) => s.emailBuilder.blockTemplates || [])

  return (
    <div className="new-block-container">
      <div id="new-block-menu" className='new-block'>
        <div className="new-block-header">
          <button className="button naked" onClick={onClose}>
            {canClose && <SvgIconsClose className="no-margin" />}
          </button>
        </div>
        <ul>
          {blockTemplates.map((template) =>
            <li key={template.id} onClick={() => handleClick(template)}>
              {ICONS[template.name] ? ICONS[template.name] : null}
              <span>{startCase(NAME_REPLACEMENTS[template.name] || template.name)}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default NewBlock
