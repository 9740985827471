import './PageContainer.scss'
import './blocks/Block.scoped.scss'
import Block from './blocks/Block'
import NewBlock from './NewBlock'
import StaticBlock from 'EmailBuilder/components/blocks/StaticBlock'

const blankBlock = ({ id, parameters }) => ({
  id: uuidv4(),
  blockTemplateId: id,
  parameters: parameters,
  digitOrder: 1,
})

const htmlBlock = (html) => (
  <div className="wipe-css header-container html-block-container">
    <div className="foundation-container">
      <table className="container">
        <tr>
          <td dangerouslySetInnerHTML={{ __html: html }} />
        </tr>
      </table>
    </div>
  </div>
)

const PageContainer = () => {
  const act = useAct()
  const newBlockMenu = useRef(null)

  const blockIds = useSel((s) => {
    const blocks = s.blocks.present.ids.map((id) => s.blocks.present.entities[id])
    return _.orderBy(blocks, 'digitOrder').map((b) => b.id)
  })

  const activeBlockId = useSel((s) => s.emailBuilder.activeBlockId)
  const headerHtmlRendered = useSel((s) => s.emailBuilder.headerHtmlRendered)
  const footerHtmlRendered = useSel((s) => s.emailBuilder.footerHtmlRendered)
  const hasUnsubscribeBlock = useSel((s) => s.emailBuilder.hasUnsubscribeBlock)
  const enableFeedbackWidget = useSel(
    (s) => s.campaign.emailBlast && s.campaign.emailBlast.enableFeedbackWidget
  )

  const editBlock = (id) => {
    act.emailBuilder.setActiveBlockId(id)
  }

  const addBlock = (template) => {
    const newBlock = blankBlock(template)
    act.email.blocks.upsert(newBlock.id, newBlock)
    setTimeout(() => act.emailBuilder.setActiveBlockId(newBlock.id))
  }

  const noBlocks = () => (
    <div>
      <NewBlock handleClick={addBlock} canClose={false} />
    </div>
  )

  return (
    <div className="foundation-container">
      {htmlBlock(headerHtmlRendered)}
      {blockIds.map((id) => (
        <Block
          key={id}
          id={id}
          active={id === activeBlockId}
          onClick={() => editBlock(id)}
          first={id === blockIds[0]}
          last={id === blockIds[blockIds.length - 1]}
        />
      ))}
      {!blockIds.length && noBlocks()}
      {enableFeedbackWidget && (
        <div className="block-container">
          <StaticBlock blockTemplate="feedback" />
        </div>
      )}
      {htmlBlock(footerHtmlRendered)}
      {hasUnsubscribeBlock && (
        <div className="block-container">
          <StaticBlock blockTemplate="unsubscribe" />
        </div>
      )}
    </div>
  )
}

export default PageContainer
